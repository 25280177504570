import React, { useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import Recaptcha from 'react-recaptcha'
import 'src/utils/string'
import TextInput from 'src/components/atoms/TextInput'
import { Button } from 'src/components/atoms/Buttons'
import { BOOLEAN } from 'src/ducks/consts'
import Checkbox from 'src/components/atoms/Checkbox'
import {
  validateContact,
  validateContactSchema
} from 'src/features/auth/duck/schema'
import { connectContactForm } from 'src/features/auth/duck/connectors'
import useValidateSchema from 'src/hooks/useValidateSchema'
import { Panel, Row, Column } from './components/atoms'
import { PanelTitle, Condition, Address, Spacer } from './components/typography'

const ContactForm = ({ className, submitContactForm }) => {
  const intl = useIntl()
  const [state, setState] = useState({
    name: '',
    email: '',
    message: '',
    regulationsAccepted: false
  })
  const [recaptchaVerified, setRecaptchaVerified] = useState(false)
  const valid = useValidateSchema(state, validateContactSchema)

  return (
    <div className={className}>
      <PanelStyled>
        <Column>
          <PanelTitle>
            <FormattedMessage id='contact.contactWithUs' />
          </PanelTitle>
          <Address>
            <h5>
              <FormattedMessage id='contact.customerSupportOffice' />
            </h5>
            <span>
              (<FormattedMessage id='contact.openedDaysOfWeek' />: 08:00-16:00)
            </span>
            <Spacer />
            <a href='tel:+48603244040'>+48 603 244 040</a>
            <a href='mailto:bok@e-containers.eu'>bok@e-containers.eu</a>
            <br />
            <span>Anna Jędrzejkowska</span>
            <span>
              <FormattedMessage id='contact.salesSpecialistWorkerRole' />
            </span>
            {/* <a href='tel:+48603244040'>+48 603 244 040</a> */}
            <a href='mailto:anna.jedrzejkowska@e-containers.eu'>
              anna.jedrzejkowska@e-containers.eu
            </a>
            <br />
            <span>Magdalena Markowska</span>
            <span>
              <FormattedMessage id='contact.salesSpecialistWorkerRole' />
            </span>
            {/* <a href='tel:+48603244040'>+48 603 244 040</a> */}
            <a href='mailto:magdalena.markowska@e-containers.eu'>
              magdalena.markowska@e-containers.eu
            </a>
            <br />
            <span>DIGITAL SERVICE ACT DSA</span>
            <span>
              <FormattedMessage id='contact.dsaLine1' />
            </span>
            <span>
              <FormattedMessage id='contact.dsaLine2' />
              <a href='mailto:dsa@e-containers.eu'>dsa@e-containers.eu</a>
            </span>
            <span>
              <FormattedMessage id='contact.dsaLine3' />
            </span>
            <br />
            <br />
            <h3>
              <FormattedMessage id='contact.marketingDepartment' />
            </h3>
            <Spacer />
            <span>Natalia Gołąbek</span>
            <span>
              <FormattedMessage id='contact.marketingSpecialistRole' />
            </span>
            <a href='tel:+48535991526'>+48 535 991 526</a>
            <a href='mailto:natalia.golabek@e-containers.eu'>
              natalia.golabek@e-containers.eu
            </a>
            <br />
            <br />
            {/* <h3>
              <FormattedMessage id='contact.developmentDepartment' />
            </h3>
            <Spacer />
            <span>Marietta Drewa</span>
            <span>
              <FormattedMessage id='contact.businessDevelopmentManager' />
            </span>
            <a href='tel:+48662401789'>+48 662 401 789</a>
            <a href='mailto:marietta.drewa@e-containers.eu'>
              marietta.drewa@e-containers.eu
            </a>
            <br />
            <br /> */}
            <span>
              RRMEDIA SP. Z O.O.
              <br />
              UL. JANKA WIŚNIEWSKIEGO 31 LOK.328
              <br />
              81-156 GDYNIA
            </span>
            <br />
            <span>
              <FormattedMessage id='contact.accountNumber' />
              <br />
              <FormattedMessage id='contact.bankAccountNumber' />
              <br />
              <FormattedMessage id='contact.bankName' />
            </span>
            <Spacer />
          </Address>

          <RowStyled>
            <TextInput
              label={intl.formatMessage({ id: 'common.name' })}
              id='name'
              name='name'
              placeholder={intl.formatMessage({ id: 'common.name' })}
              value={state.name}
              onChange={(name, value) => setState({ ...state, [name]: value })}
              validate={validateContact}
              required
            />
          </RowStyled>
          <RowStyled>
            <TextInput
              label={intl.formatMessage({ id: 'common.emailAddress' })}
              id='email'
              name='email'
              placeholder={intl.formatMessage({ id: 'common.email' })}
              type='email'
              value={state.email}
              onChange={(name, value) => setState({ ...state, [name]: value })}
              validate={validateContact}
              required
            />
          </RowStyled>
          <RowStyled>
            <TextInput
              label={intl.formatMessage({ id: 'common.message' })}
              id='message'
              name='message'
              placeholder={intl.formatMessage({ id: 'common.message' })}
              multiline
              rows={5}
              value={state.message}
              onChange={(name, value) => setState({ ...state, [name]: value })}
              validate={validateContact}
              required
            />
          </RowStyled>
          <RowStyled>
            <Condition>
              <Checkbox
                name='regulationsAccepted'
                value={state.regulationsAccepted}
                onChange={() =>
                  setState({
                    ...state,
                    regulationsAccepted: !state.regulationsAccepted
                  })
                }
                option={{ value: BOOLEAN.YES }}
              />
              <Agree>
                <FormattedMessage id='contact.agree' />
              </Agree>
            </Condition>
          </RowStyled>
          <RowStyled>
            <Recaptcha
              sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITEKEY}
              render='explicit'
              verifyCallback={() => setRecaptchaVerified(true)}
            />
          </RowStyled>
          <Button
            active={valid && recaptchaVerified}
            onClick={() =>
              submitContactForm({
                email: state.email,
                name: state.name,
                message: state.message
              })
            }
          >
            {intl.formatMessage({ id: 'common.send' })}
          </Button>
        </Column>
      </PanelStyled>
    </div>
  )
}

const styledComponent = styled(ContactForm)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${Button} {
    width: 100%;
  }
`

export default connectContactForm(styledComponent)

const PanelStyled = styled(Panel)`
  padding-top: 2.6rem;
`

const RowStyled = styled(Row)`
  margin-bottom: 1.2rem;
`

const Agree = styled.span`
  &:after {
    content: '*';
    color: red;
    font-size: 0.75rem;
  }
`
