import React from 'react'
import ThemeProvider from 'src/theme/ThemeProvider'
import Layout from 'src/theme/Layout'
import ContactForm from 'src/features/auth/ContactForm'
import EuFinancingLogoLarge from 'src/components/header/components/EuFinancingLogoLarge'
import EUFinancingDialog from 'src/components/EUFinancingDialog'

export default () => (
  <ThemeProvider>
    <Layout>
      <ContactForm />
      <EUFinancingDialog />
    </Layout>
    <EuFinancingLogoLarge />
  </ThemeProvider>
)
